import { lazy } from 'react';

export const HomePage = lazy(() => import('../pages/home/HomePage'));
export const LoginPage = lazy(() => import('../pages/login/LoginPage'));
export const MultiFactorPage = lazy(() =>
  import('../pages/multi-factor-authentication/MultiFactorPage'),
);
export const MultiFactorSetupPage = lazy(() =>
  import('../pages/multi-factor-setup/MultiFactorSetupPage'),
);
export const RegistrationPage = lazy(() =>
  import('../pages/registration/RegistrationPage'),
);
export const ForgotCredentialsPage = lazy(() =>
  import('../pages/forgot-credentials/ForgotCredentialsPage'),
);
export const ResetPasswordPage = lazy(() =>
  import('../pages/reset-password/ResetPasswordPage'),
);
export const FilmPage = lazy(() => import('../pages/film/FilmPage'));
export const PrivacyPolicyPage = lazy(() =>
  import('../pages/privacy-policy/PrivacyPolicyPage'),
);
export const ContactUsPage = lazy(() =>
  import('../pages/contact-us/ContactUsPage'),
);
export const LibraryPage = lazy(() => import('../pages/library/LibraryPage'));
export const AdvancedSearchPage = lazy(() =>
  import('../pages/advanced-search/AdvancedSearchPage'),
);
export const DistributorsPage = lazy(() =>
  import('../pages/distributors/DistributorsPage'),
);
export const FilmDetailsPage = lazy(() =>
  import('../pages/film-details/FilmDetailsPage'),
);
export const SearchResultsPage = lazy(() =>
  import('../pages/search-results/SearchResultsPage'),
);
export const DistributorFilmsPage = lazy(() =>
  import('../pages/distributor-films/DistributorFilmsPage'),
);
export const DataAuditPage = lazy(() =>
  import('../pages/data-audit/DataAuditPage'),
);
export const FilmsByMetricPage = lazy(() =>
  import('../pages/films-by-metric/FilmsByMetricPage'),
);
export const EditProfilePage = lazy(() =>
  import('../pages/edit-profile/EditProfilePage'),
);
export const PredictionsPage = lazy(() =>
  import('../pages/predictions/PredictionsPage'),
);
export const PersonPage = lazy(() => import('../pages/person/PersonPage'));
export const AnalyticsReportPage = lazy(() =>
  import('../pages/analitics-report/AnalyticsReportPage'),
);
export const ReportPrintPage = lazy(() =>
  import('../pages/report-print/ReportPrintPage'),
);
export const LandingPage = lazy(() => import('../pages/landing/LandingPage'));
export const RegistrationCompletedPage = lazy(() =>
  import('../pages/registration-completed/RegistrationCompletedPage'),
);
export const ResetPasswordCheckEmailPage = lazy(() =>
  import('../pages/reset-password-check-email/ResetPasswordCheckEmail'),
);
export const NewPasswordEmailRedirectPage = lazy(() =>
  import('../pages/new-password-email-redirect/NewPasswordEmailRedirectPage'),
);
