import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  Tooltip,
} from 'reactstrap';
import headerLogo from 'images/superb-logo-black.svg';
import { routes } from 'routes/routes';
import { logOut, getFromLocalStorage } from 'services/localstorage';
import { UserContext } from 'providers/User';
import SearchField from 'components/searchField/SearchField';
import constants from 'utils/constants';
import { useLocation } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import { useFlag } from '@unleash/proxy-client-react';
import {
  NavbarComponent,
  IconsContainer,
  NavLinkComponent,
  UserIconComponent,
  NavbarContainer,
  LinkWrapper,
  IconLink,
} from './elements';
import SIDEBAR_ITEMS from '../../pages/film-details/SidebarItems';
import NotificationsPopup from './notifications/NotificationsPopup';
import './PrivateHeader.scss';

const PrivateHeader = ({
  displaySearchFieldComponent,
  providePublicBrandInfoOnly,
}) => {
  const { updateData } = useContext(UserContext);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const toggleDropdown = () => {
    setIsOpenDropdown(!isOpenDropdown);
    handleIconLinkClick(privateHeaderIconLinks.profile);
  };
  const privateIconsAndSearchFieldComponentWrapperClassName = `show justify-content-between ${
    displaySearchFieldComponent ? 'navbar-collapse' : ''
  } ${providePublicBrandInfoOnly ? 'hide' : ''}`;
  const location = useLocation();
  const query = useQuery();
  const isNotificationsFeatureActive = useFlag(
    constants.FEATURE_FLAG.notifications,
  );

  const userName = getFromLocalStorage('username');

  const { privateHeaderIconLinks } = constants;

  const defaultIconLinksActiveState = {
    [privateHeaderIconLinks.addFilm]: false,
    [privateHeaderIconLinks.library]: false,
    [privateHeaderIconLinks.distributors]: false,
    [privateHeaderIconLinks.notifications]: false,
    [privateHeaderIconLinks.profile]: false,
  };

  const [iconLinksActiveState, setIconLinksActiveState] = useState(
    defaultIconLinksActiveState,
  );

  const handleIconLinkClick = (iconLinkName) => {
    if (
      iconLinkName === privateHeaderIconLinks.addFilm ||
      iconLinkName === privateHeaderIconLinks.library ||
      iconLinkName === privateHeaderIconLinks.distributors
    ) {
      setIconLinksActiveState({
        ...defaultIconLinksActiveState,
        [iconLinkName]: true,
      });
    } else {
      const updatedIconLinksActiveState = { ...iconLinksActiveState };

      if (iconLinkName === privateHeaderIconLinks.notifications) {
        updatedIconLinksActiveState[
          privateHeaderIconLinks.notifications
        ] = !iconLinksActiveState[privateHeaderIconLinks.notifications];

        updatedIconLinksActiveState[privateHeaderIconLinks.profile] = false;
      } else if (iconLinkName === privateHeaderIconLinks.profile) {
        updatedIconLinksActiveState[
          privateHeaderIconLinks.profile
        ] = !iconLinksActiveState[privateHeaderIconLinks.profile];

        updatedIconLinksActiveState[
          privateHeaderIconLinks.notifications
        ] = false;
      }

      setIconLinksActiveState(updatedIconLinksActiveState);
    }
  };

  const handleLocationChange = () => {
    if (
      location.pathname.includes(routes.FILM_DETAILS) &&
      location.search.includes(
        `subpage=${SIDEBAR_ITEMS.Overview.short}&mode=edit`,
      ) &&
      !query.get('id')
    ) {
      setIconLinksActiveState({
        ...defaultIconLinksActiveState,
        [privateHeaderIconLinks.addFilm]: true,
      });
    } else if (location.pathname.includes(routes.LIBRARY)) {
      setIconLinksActiveState({
        ...defaultIconLinksActiveState,
        [privateHeaderIconLinks.library]: true,
      });
    } else if (location.pathname.includes(routes.DISTRIBUTORS)) {
      setIconLinksActiveState({
        ...defaultIconLinksActiveState,
        [privateHeaderIconLinks.distributors]: true,
      });
    } else {
      setIconLinksActiveState({
        ...defaultIconLinksActiveState,
      });
    }
  };

  useEffect(() => {
    handleLocationChange();
  }, [location]);

  const defaultPrivateHeaderIconsTooltipOpen = {
    addFilm: false,
    library: false,
    distributors: false,
  };

  const [
    privateHeaderIconsTooltipOpen,
    setPrivateHeaderIconsTooltipOpen,
  ] = useState({
    ...defaultPrivateHeaderIconsTooltipOpen,
  });

  const togglePrivateHeaderIconsTooltip = (privateHeaderIcon) =>
    setPrivateHeaderIconsTooltipOpen({
      ...privateHeaderIconsTooltipOpen,
      [privateHeaderIcon]: !privateHeaderIconsTooltipOpen[privateHeaderIcon],
    });

  return (
    <NavbarContainer
      style={{ position: 'sticky', top: '0', zIndex: '999' }}
      className="privateHeader"
    >
      <NavbarComponent light expand="sm" className="privateHeaderNavbar">
        <LinkWrapper
          to={routes.ROOT}
          style={{ padding: '5px 0', marginRight: 16 }}
        >
          <img
            src={headerLogo}
            className="logo"
            alt="Header Logo"
            height="37px"
          />
        </LinkWrapper>

        <div className={privateIconsAndSearchFieldComponentWrapperClassName}>
          {displaySearchFieldComponent && <SearchField></SearchField>}

          <IconsContainer className="justify-content-start ml-auto">
            <NavLinkComponent
              href={`/film-details?subpage=${SIDEBAR_ITEMS.Overview.short}&mode=edit`}
              id="privateHeaderAddFilmIcon"
            >
              <span
                className={`icon-film-add ${
                  iconLinksActiveState[privateHeaderIconLinks.addFilm]
                    ? 'activeIconLink'
                    : ''
                }`}
              />
            </NavLinkComponent>

            <Tooltip
              placement="bottom"
              isOpen={privateHeaderIconsTooltipOpen.addFilm}
              target="privateHeaderAddFilmIcon"
              toggle={() => togglePrivateHeaderIconsTooltip('addFilm')}
            >
              Add a film
            </Tooltip>

            <IconLink to={routes.LIBRARY} id="privateHeaderLibraryIcon">
              <span
                className={`icon-book ${
                  iconLinksActiveState[privateHeaderIconLinks.library]
                    ? 'activeIconLink'
                    : ''
                }`}
              />
            </IconLink>

            <Tooltip
              placement="bottom"
              isOpen={privateHeaderIconsTooltipOpen.library}
              target="privateHeaderLibraryIcon"
              toggle={() => togglePrivateHeaderIconsTooltip('library')}
            >
              Library
            </Tooltip>

            <IconLink
              to={routes.DISTRIBUTORS}
              id="privateHeaderDistributorsIcon"
            >
              <span
                className={`icon-globe ${
                  iconLinksActiveState[privateHeaderIconLinks.distributors]
                    ? 'activeIconLink'
                    : ''
                }`}
              />
            </IconLink>

            <Tooltip
              placement="bottom"
              isOpen={privateHeaderIconsTooltipOpen.distributors}
              target="privateHeaderDistributorsIcon"
              toggle={() => togglePrivateHeaderIconsTooltip('distributors')}
            >
              Distributors
            </Tooltip>

            {isNotificationsFeatureActive && (
              <NotificationsPopup
                handleNotificationsIconLinkClick={() =>
                  handleIconLinkClick(privateHeaderIconLinks.notifications)
                }
              ></NotificationsPopup>
            )}

            <ButtonDropdown
              nav
              className="d-none d-sm-block ml-2"
              isOpen={isOpenDropdown}
              toggle={toggleDropdown}
              style={{ zIndex: 11 }}
            >
              <UserIconComponent
                nav
                tag="a"
                className={`rounded-circle icon-user bg-light btn btn-lg ${
                  iconLinksActiveState[privateHeaderIconLinks.profile]
                    ? 'activeProfileIcon'
                    : ''
                }`}
                onClick={() =>
                  handleIconLinkClick(privateHeaderIconLinks.profile)
                }
              />

              <DropdownMenu right>
                <h6 className="dropdown-header">{userName}</h6>

                <div className="dropdown-divider" />

                <DropdownItem href={routes.DATA_AUDIT}>Dashboard</DropdownItem>

                <DropdownItem href={routes.PREDICTIONS}>
                  Predictions
                </DropdownItem>

                <DropdownItem href={routes.EDIT_PROFILE}>
                  Edit Profile
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    logOut();
                    updateData({ active: false });
                  }}
                  href={routes.LOGIN}
                >
                  Log out
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </IconsContainer>
        </div>
      </NavbarComponent>
    </NavbarContainer>
  );
};

PrivateHeader.propTypes = {
  displaySearchFieldComponent: PropTypes.any,
  providePublicBrandInfoOnly: PropTypes.bool,
};

export default PrivateHeader;
