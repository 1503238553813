import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import UserProvider from 'providers/User';
import { routes } from 'routes/routes';
import * as pages from 'routes/async';
import { ToastContainer } from 'react-toastify';
import { PrivatePrintRoute } from 'components/private-route/PrivatePrintRoute';
import { PrivateRoute } from './components/private-route/PrivateRoute';
import { PublicRoute } from './components/public-route/PublicRoute';
import history from './utils/history';
import { FilmDetailsProvider } from './pages/film-details/hooks/useFilmDetails';

import 'react-toastify/dist/ReactToastify.css';

const { FilmDetailsPage, FilmPage } = pages;

const App = () => (
  <BrowserRouter history={history} basename="/">
    <UserProvider>
      <ToastContainer autoClose={false} hideProgressBar />
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PrivateRoute
            exact
            path={routes.ROOT}
            component={pages.HomePage}
            redirectToLanding
          />
          <PrivateRoute
            exact
            path={routes.FILM}
            component={() => (
              <FilmDetailsProvider>
                <FilmPage />
              </FilmDetailsProvider>
            )}
            displaySearchFieldComponent
          />
          <PrivateRoute
            exact
            path={routes.PRIVACY_POLICY}
            component={pages.PrivacyPolicyPage}
            providePublicBrandInfoOnly
          />
          <PrivateRoute
            exact
            path={routes.CONTACT_US}
            component={pages.ContactUsPage}
            providePublicBrandInfoOnly
            displayFooterBasedOnPageContent
          />
          <PrivateRoute
            exact
            path={routes.LIBRARY}
            component={pages.LibraryPage}
            displaySearchFieldComponent
            subtractFooterHeight
          />
          <PrivateRoute
            exact
            path={routes.ADVANCED_SEARCH}
            component={pages.AdvancedSearchPage}
          />
          <PrivateRoute
            exact
            path={routes.DISTRIBUTORS}
            component={pages.DistributorsPage}
            displaySearchFieldComponent
          />
          <PrivateRoute
            exact
            path={routes.DISTRIBUTOR_FILMS}
            component={pages.DistributorFilmsPage}
            displaySearchFieldComponent
          />
          <PrivateRoute
            exact
            path={routes.FILM_DETAILS}
            component={() => (
              <FilmDetailsProvider>
                <FilmDetailsPage />
              </FilmDetailsProvider>
            )}
            displaySearchFieldComponent
          />
          <PrivateRoute
            exact
            path={routes.SEARCH_RESULTS}
            component={pages.SearchResultsPage}
            displaySearchFieldComponent
            displayFooterBasedOnPageContent
          />
          <PrivateRoute
            exact
            path={routes.DATA_AUDIT}
            component={pages.DataAuditPage}
            displaySearchFieldComponent
          />
          <PrivateRoute
            exact
            path={routes.FILMS_BY_METRIC}
            component={pages.FilmsByMetricPage}
            displaySearchFieldComponent
          />
          <PrivateRoute
            exact
            path={routes.EDIT_PROFILE}
            component={pages.EditProfilePage}
            displaySearchFieldComponent
            displayFooterBasedOnPageContent
          />
          <PrivateRoute
            exact
            path={routes.PREDICTIONS}
            component={pages.PredictionsPage}
            displayFooterBasedOnPageContent
          />
          <PrivateRoute
            exact
            path={routes.PERSON}
            component={pages.PersonPage}
            displaySearchFieldComponent
            displayFooterBasedOnPageContent
          />
          <PrivateRoute
            exact
            path={routes.ANALYTICS_REPORT}
            component={pages.AnalyticsReportPage}
            displaySearchFieldComponent
            displayFooterBasedOnPageContent
          />
          <PrivatePrintRoute
            exact
            path={routes.REPORT_PRINT}
            component={pages.ReportPrintPage}
          />
          <PublicRoute exact path={routes.LOGIN} component={pages.LoginPage} />
          <Route
            exact
            path={routes.LANDING}
            render={() => <pages.LandingPage />}
          />
          {/* <PublicRoute
            exact
            path={routes.MULTI_FACTOR}
            component={pages.MultiFactorPage}
          /> */}
          {/* <PublicRoute
            exact
            path={routes.MULTI_FACTOR_SETUP}
            component={pages.MultiFactorSetupPage}
          /> */}
          <PublicRoute
            exact
            path={routes.REGISTRATION}
            component={pages.RegistrationPage}
          />
          <PublicRoute
            exact
            path={routes.REGISTRATION_COMPLETED}
            component={pages.RegistrationCompletedPage}
          />
          <PublicRoute
            exact
            path={routes.FORGOT_CREDENTIALS}
            component={pages.ForgotCredentialsPage}
          />
          <PublicRoute
            exact
            path={routes.RESET_PASSWORD}
            component={pages.ResetPasswordPage}
          />
          <PublicRoute
            exact
            path={routes.RESET_PASSWORD_CHECK_EMAIL}
            component={pages.ResetPasswordCheckEmailPage}
          />
          <PublicRoute
            exact
            path={routes.NEW_PASSWORD_EMAIL_REDIRECT}
            component={pages.NewPasswordEmailRedirectPage}
          />
        </Switch>
      </Suspense>
    </UserProvider>
  </BrowserRouter>
);

export default App;
