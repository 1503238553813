import React from 'react';
import PropTypes from 'prop-types';
import useViewport from 'hooks/useViewport';
import { routes } from 'routes/routes';
import {
  PrivateFooterLinkComponent,
  PrivateFooterWrapper,
  PrivateFooterContainer,
} from './elements';
import './PrivateFooter.scss';

const PrivateFooter = ({ customRef }) => {
  const viewport = useViewport();
  return (
    <PrivateFooterWrapper
      ref={customRef}
      justifyContent={viewport.isMedium ? 'center' : 'space-between'}
      flexDirection={viewport.isMedium ? 'column' : 'raw'}
      className="privateFooter"
    >
      <PrivateFooterContainer
        order={viewport.isMedium ? 2 : 1}
        flexDirection={viewport.isSmall ? 'column' : 'raw'}
      >
        <div>
          © 2024{' '}
          <PrivateFooterLinkComponent
            display="inline-flex"
            href="https://www.cinematicventures.com/"
            padding={viewport.isSmall ? '4px 0' : '4px 4px 4px 0'}
            className="companyWebsiteLink"
          >
            Superb Intelligence, LLC.
          </PrivateFooterLinkComponent>
        </div>
        <div>All Rights Reserved.</div>
      </PrivateFooterContainer>
      <PrivateFooterContainer
        order={viewport.isMedium ? 1 : 2}
        flexDirection={viewport.isSmall ? 'column' : 'raw'}
      >
        <PrivateFooterLinkComponent
          display="inline-flex"
          href={routes.PRIVACY_POLICY}
          padding="4px 8px"
        >
          Privacy Policy
        </PrivateFooterLinkComponent>
        |
        <PrivateFooterLinkComponent
          display="inline-flex"
          href={routes.CONTACT_US}
          padding={viewport.isSmall ? '4px 0 4px 0' : '4px 0 4px 8px'}
        >
          Contact
        </PrivateFooterLinkComponent>
      </PrivateFooterContainer>
    </PrivateFooterWrapper>
  );
};

PrivateFooter.propTypes = {
  customRef: PropTypes.any,
};

export default PrivateFooter;
